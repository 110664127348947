export default {
  actionIcon: "StarIcon",
  highlightColor: "warning",
  data: [
    // {
    //   label: "Home",
    //   url: "/",
    //   labelIcon: "HomeIcon",
    //   highlightAction: true,
    //   roles: ["ADMIN", "CUSTOMER", "HOUSEKEEPER", "BACKOFFICE", 'SUPPORT', "DRIVER", "SUBDRIVER"]
    // },
    {
      label: "Charts",
      url: "/charts",
      labelIcon: "PieChartIcon",
      highlightAction: true,
      roles: ["CUSTOMER", "ADMIN", "DRIVER","SUPPORT"],
    },
    {
      label: "Package",
      url: "/package",
      labelIcon: "PackageIcon",
      highlightAction: true,
      roles: [
        "SUBACCOUNTANT",
        "ACCOUNTANT",
        "SUBCUSTOMER",
        "ADMIN",
        "CUSTOMER",
        "HOUSEKEEPER",
        "BACKOFFICE",
        "SUPPORT",
        "DRIVER",
        "SUBDRIVER",
        "COMMERCIAL",
        "COLLECTOR",
      ],
    },
    {
      label: "Billing",
      url: "/billing",
      labelIcon: "DollarSignIcon",
      highlightAction: true,
      roles: ["SUBACCOUNTANT", "ACCOUNTANT", "ADMIN", "CUSTOMER", "DRIVER"],
    },
    {
      label: "Demandes",
      url: "/user-requests",
      labelIcon: "FileIcon",
      highlightAction: true,
      roles: ["SUBACCOUNTANT", "ACCOUNTANT", "ADMIN"],
    },
    {
      label: "Print",
      url: "/print",
      labelIcon: "PrinterIcon",
      highlightAction: true,
      roles: [
        "ACCOUNTANT",
        "SUBACCOUNTANT",
        "COMMERCIAL",
        "ADMIN",
        "CUSTOMER",
        "SUBCUSTOMER",
        "DRIVER",
        "BACKOFFICE",
        "SUPPORT",
        "HOUSEKEEPER",
      ],
    },
    {
      label: "Pallets",
      url: "/pallets",
      labelIcon: "TruckIcon",
      highlightAction: true,
      roles: [
        "ADMIN",
        "HOUSEKEEPER",
        "ACCOUNTANT",
        "SUBACCOUNTANT",
        "BACKOFFICE",
        "SUPPORT",
        "DRIVER",
      ],
    },
    {
      label: "Livreurs",
      url: "/subdrivers",
      labelIcon: "UserIcon",
      highlightAction: true,
      manager: true,
      roles: ["DRIVER"],
    },
    {
      label: "Team",
      url: "/team",
      labelIcon: "UserIcon",
      hasTeam: true,
      highlightAction: true,
      roles: ["CUSTOMER"],
    },
    {
      label: "Réclamations",
      url: "/tickets",
      labelIcon: "InfoIcon",
      highlightAction: true,
      roles: [
        "ADMIN",
        "CUSTOMER",
        "HOUSEKEEPER",
        "DRIVER",
        "BACKOFFICE",
        "SUPPORT",
      ],
    },
    {
      label: "Stock",
      url: "/stock",
      labelIcon: "BoxIcon",
      highlightAction: true,
      hasStock: true,
      roles: ["ADMIN", "HOUSEKEEPER", "BACKOFFICE", "SUPPORT"],
    },
    {
      label: "Warehouses",
      url: "/warehouses",
      labelIcon: "ArchiveIcon",
      highlightAction: true,
      roles: ["ACCOUNTANT", "ADMIN"],
    },
    {
      label: "House",
      url: "/house",
      labelIcon: "ArchiveIcon",
      highlightAction: true,
      roles: [
        "ADMIN",
        "SUBACCOUNTANT",
        "ACCOUNTANT",
        "HOUSEKEEPER",
        "BACKOFFICE",
        "SUPPORT",
      ],
    },
    {
      label: "Logs",
      url: "/logs",
      labelIcon: "InfoIcon",
      highlightAction: true,
      roles: ["ADMIN"],
    },
    {
      label: "Statistics",
      url: "/statistics",
      labelIcon: "InfoIcon",
      highlightAction: true,
      roles: ["ADMIN"],
    },
  ],
};
